@import './shared/styles/_vuetify-overrides.scss';




































































































































































































































































































































































































































.error-message {
	color: red;
}
